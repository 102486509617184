import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import KeyboardEventHandler from "react-keyboard-event-handler";
import {
	iconMap,
	getCheckoutMenuItems,
	encodeFullpath,
	ocrOnDemandAllowed,
	canShowInlineImagePreview,
} from "../utilities/utilities";
import {
	getSignedUrlAndDownload,
	getSignedUrlForDocumentAndDownload,
	createNewFolder,
	bulkUploadMultipleFiles,
	changeFolderName,
	deleteDocuments,
	deleteFolders,
	restoreDocuments,
	restoreFolders,
	setFavorite,
	setFavoriteFolder,
	deleteEmails,
	restoreEmails,
	deleteNotes,
	restoreNotes,
	accessCreateNote,
	setLockStatus,
	openDocumentInLauncher,
	renameDocument,
	getSignedUrlForEmailAndDownload,
	getContentForDocumentPreview,
	textAreaLabel,
	textAreaName,
	convertDocumentToPDF,
	aiConversationAboutDocument,
	createNoteInRootOfMatter,
} from "../actions/documentActions";

import {
	showNewFolderDialog,
	showEditFolderDialog,
} from "../actions/folderActions";

import {
	cut,
	copy,
	paste,
	selectAll,
	deleteMultiselect,
	restoreMultiselect,
	setAllCheckboxes,
	downloadAllMultiselect,
	externalSendAllMultiselect,
} from "../actions/multiselectActions";
import { exportMatter } from "../actions/matterActions";
import { testGetAllDocs } from "../actions/jobProgressActions";
import { requestOCR } from "../actions/ocrJobProgressActions";
import { uploadMultipleEmails, updateEmailNote } from "../actions/emailActions";
import { updateUserSetting } from "../actions/userSettingsActions";
import { setResizeElement } from "../actions/globalScreenSizeActions";
import InteractiveTableCell, {
	ActionsTableCell,
} from "./Tables/InteractiveTableCell";
import SelectTable from "./Tables/SelectTable";
import Breadcrumbs from "./Breadcrumbs";
import { ButtonWithIcon, ButtonWithRouter } from "./ButtonWithIcon";
import { DropDownButtonWithRouter, DropDownButton } from "./DropDownButton";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "react-table/react-table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openGlobalDialog } from "../actions/globalEditDialogActions";
import FileDropzone from "./FileDropzone";
import { withStyles } from "@material-ui/core/styles";
import {
	humanFileSize,
	shortDateFormat,
	filmCreditsFlexHelper,
	splitFilename,
	pluralizeWithNum,
	convertToPDFAllowed,
} from "../utilities/utilities";
import {
	linkLXMatterWithClio,
	unlinkLXMatterfromClio,
	openLinkExistingMatterToClioDialog,
	clioIntegrationAccessRedirectCodeStatus,
} from "../actions/clioIntegrationActions";
import DocumentSlidingPane from "./DocumentPreviewRightPane";
import ButtonLink from "./ButtonLink";

const RowCheckbox = withStyles({
	root: {
		padding: 0,
	},
})((props) => <Checkbox color="primary" {...props} />);

const filterMap = {
	All: () => true,
	Active: (row) => !row.isDeleted,
	Deleted: (row) => row.isDeleted,
};
const copyPasteCompatibility = {
	"docs/": "documents",
	"public/": "documents",
	"private/": "documents",
	"email/": "email",
	"notes/": "notes",
};

const selectionRootMap = {
	"docs/": "selectedDocuments",
	"email/": "selectedEmails",
	"notes/": "selectedNotes",
	"public/": "selectedPublicDocuments",
	"private/": "selectedPrivateDocuments",
};
const objectTypeMap = {
	File: "Object",
	Email: "Object",
	Note: "Object",
	Folder: "Folder",
};

const IdCell = (row) => {
	//note that under this implementation the folder is still filterable
	//via the ID filter text field.  Makes the results a bit confusing perhaps, but useful
	//if you are looking for a folder by ID
	return (
		<div style={{ textAlign: "left" }} data-id={row.value}>
			{row.original.type === "Folder" ? "" : row.value}
		</div>
	);
};

const NameCell = (props) => {
	const { icon, color } = iconMap(props.row);
	return InteractiveTableCell({ ...props, icon, iconColor: color });
};

const defaultOrderBy = (arr, funcs, dirs, indexKey) => {
	return arr.sort((rowA, rowB) => {
		for (let i = 0; i < funcs.length; i += 1) {
			const comp = funcs[i];
			const desc = dirs[i] === false || dirs[i] === "desc";
			const sortInt = comp(rowA, rowB);
			if (sortInt) {
				return desc ? -sortInt : sortInt;
			}
		}
		// Use the row index for tie breakers
		return dirs[0]
			? rowA[indexKey] - rowB[indexKey]
			: rowB[indexKey] - rowA[indexKey];
	});
};

const foldersOnTopOrderedBy = (arr, funcs, dirs, indexKey) => {
	function sortFolders(a, b) {
		// force null and undefined to the bottom
		a = a === null || a === undefined ? "" : a;
		b = b === null || b === undefined ? "" : b; // force any string values to lowercase

		a = typeof a === "string" ? a.toLowerCase() : a;
		b = typeof b === "string" ? b.toLowerCase() : b; // Return either 1 or -1 to indicate a sort priority

		if (a > b) {
			return 1;
		}

		if (a < b) {
			return -1;
		} // returning 0, undefined or any falsey value will use subsequent sorts or
		// the index as a tiebreaker

		return 0;
	}
	return arr.sort((rowA, rowB) => {
		const aFolder = rowA._original.type === "Folder";
		const bFolder = rowB._original.type === "Folder";
		for (let i = 0; i < funcs.length; i += 1) {
			const comp = funcs[i];
			const desc = dirs[i] === false || dirs[i] === "desc";
			const sortInt = comp(rowA, rowB);
			if (aFolder && !bFolder) {
				return -1;
			}
			if (!aFolder && bFolder) {
				return 1;
			}
			if (aFolder && bFolder) {
				const folderSortInt = sortFolders(
					rowA._original.filename,
					rowB._original.filename
				);
				return folderSortInt;
			}
			if (sortInt) {
				return desc ? -sortInt : sortInt;
			}
		}
		// Use the row index for tie breakers
		return dirs[0]
			? rowA[indexKey] - rowB[indexKey]
			: rowB[indexKey] - rowA[indexKey];
	});
};
class DocumentList extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			filter: "All",
			showDeleted: false,
			isPaneOpen: false,
			previewSelectedRow: {},
			aiMode: false,
			docCanBeOpenedInLauncher: false,
			itemsMatchingFilter: [],
		};
		this.clickedFile = this.clickedFile.bind(this);
		this.clickedEmail = this.clickedEmail.bind(this);
		this.uploadFileHandler = this.uploadFileHandler.bind(this);
		this.bulkFileListChangeHandler =
			this.bulkFileListChangeHandler.bind(this);
		this.fileListChangeHandler = this.fileListChangeHandler.bind(this);
		this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
		this.toggleFoldersOnTop = this.toggleFoldersOnTop.bind(this);
		this.newButton = this.newButton.bind(this);
		this.folderCell = this.folderCell.bind(this);
		this.documentCell = this.documentCell.bind(this);
		this.documentActionsCell = this.documentActionsCell.bind(this);
		this.emailCell = this.emailCell.bind(this);
		this.emailActionsCell = this.emailActionsCell.bind(this);
		this.noteCell = this.noteCell.bind(this);
		this.noteActionsCell = this.noteActionsCell.bind(this);
		this.handleKeyboardShortcut = this.handleKeyboardShortcut.bind(this);
		this.headerBoxClicked = this.headerBoxClicked.bind(this);
		this.cut = this.cut.bind(this);
		this.copy = this.copy.bind(this);
		this.paste = this.paste.bind(this);
		this.testGetAll = this.testGetAll.bind(this);
		this.renameDocumentHandler = this.renameDocumentHandler.bind(this);
		this.makeUploadsMenu = this.makeUploadsMenu.bind(this);
		this.makeActionsMenu = this.makeActionsMenu.bind(this);
		this.openDocumentPreviewRightPane =
			this.openDocumentPreviewRightPane.bind(this);
		this.closeRightPane = this.closeRightPane.bind(this);
		this.openEmailPreviewRightPane =
			this.openEmailPreviewRightPane.bind(this);
		this.openDocumentAIRightPane = this.openDocumentAIRightPane.bind(this);
		this.setFavoriteFromPreview = this.setFavoriteFromPreview.bind(this);
		this.emailColumnConfig = [
			{
				Header: "ID",
				accessor: "id",
				width: 50,
				Cell: IdCell,
			},
			{
				Header: "Subject",
				accessor: "subjectline",
				Cell: (row) =>
					row.original.type === "Folder"
						? this.folderCell(row)
						: this.emailCell(row),
				filterValueMethod: (row) =>
					row._original.type === "Folder"
						? row._original.filename
						: row.subjectline,
				wildcard: true,
				unhidable: true,
			},
			{ Header: "Short Note", accessor: "notes", wildcard: true },
			{ Header: "From", accessor: "emailFrom", wildcard: true },
			{ Header: "To", accessor: "emailTo", wildcard: true },
			{
				Header: "Size",
				accessor: "filesize",
				filterable: false,
				Cell: (row) =>
					row.original.type === "Email"
						? humanFileSize(row.value, true)
						: "",
			},
			{
				Header: "Date",
				accessor: "date",
				Cell: (row) => (row.value ? shortDateFormat(row.value) : ""),
				filterValueMethod: (x) => shortDateFormat(x.date),
			},
			{ Header: "Attachments", accessor: "numAttachments", width: 50 },
			{
				Header: "",
				accessor: "actions",
				width: 32,
				sortable: false,
				filterable: false,
				Cell: (row) =>
					row.original.type === "Folder"
						? this.folderActionsCellNoFavorite(row)
						: this.emailActionsCell(row),
			},
		];
		this.notesColumnConfig = [
			{
				Header: "ID",
				accessor: "id",
				width: 50,
				Cell: IdCell,
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: (row) =>
					row.original.type === "Folder"
						? this.folderCell(row)
						: this.noteCell(row),
				filterValueMethod: (row) =>
					row._original.type === "Folder"
						? row._original.filename
						: row.name,
				wildcard: true,
				unhidable: true,
			},
			{
				Header: "Created On",
				accessor: "createdOn",
				Cell: (row) => (row.value ? shortDateFormat(row.value) : ""),
				filterValueMethod: (x) => shortDateFormat(x.createdOn),
			},
			{ Header: "Created By", accessor: "createdBy", wildcard: true },
			{
				Header: "Modified On",
				accessor: "modifiedOn",
				Cell: (row) => (row.value ? shortDateFormat(row.value) : ""),
				filterValueMethod: (x) => shortDateFormat(x.modifiedOn),
			},
			{ Header: "Modified By", accessor: "modifiedBy", wildcard: true },
			{
				Header: "",
				accessor: "actions",
				width: 32,
				sortable: false,
				filterable: false,
				Cell: (row) =>
					row.original.type === "Folder"
						? this.folderActionsCellNoFavorite(row)
						: this.noteActionsCell(row),
			},
		];

		this.docsColumnConfig = [
			{
				Header: "ID",
				accessor: "id",
				width: 60,
				Cell: IdCell,
			},
			{
				Header: "Name",
				accessor: "filename",
				width: 400,
				Cell: (row) => {
					return (
						<div>
							{row.original.type === "Folder"
								? this.folderCell(row)
								: this.documentCell(row)}
						</div>
					);
				},
				wildcard: true,
				unhidable: true,
			},
			{
				Header: "Ver",
				accessor: "versionNumber",
				width: 40,
				filterable: false,
			},
			{
				Header: "Size",
				accessor: "size",
				filterable: false,
				Cell: (row) =>
					row.original.type === "File"
						? humanFileSize(row.value, true)
						: "",
			},
			{
				Header: "Tags",
				accessor: "tags",
				Cell: (row) =>
					row.value
						? row.value.map((x) => (
								<div className="tagPill" key={x.name}>
									{x.name}
								</div>
							))
						: "",
				filterValueMethod: (x) =>
					(x.tags || []).map((t) => t.name).join(", "),
			},
			{
				Header: "Document Type",
				accessor: "documentType",
				wildcard: true,
			},
			{ Header: "Status", accessor: "documentStatus", wildcard: true },
			{ Header: "Locked By", accessor: "lockedBy", wildcard: true },
			{
				Header: "Created On",
				accessor: "createdAt",
				filterable: true,
				Cell: (row) => (row.value ? shortDateFormat(row.value) : ""),
				filterValueMethod: (x) => shortDateFormat(x.createdAt),
			},
			{
				Header: "Last Modified",
				accessor: "lastModified",
				filterable: true,
				Cell: (row) => (row.value ? shortDateFormat(row.value) : ""),
				filterValueMethod: (x) => shortDateFormat(x.lastModified),
			},

			{
				Header: "",
				accessor: "actions",
				width: 100,
				sortable: false,
				filterable: false,
				Cell: (row) =>
					row.original.type === "Folder"
						? this.folderActionsCell(row)
						: this.documentActionsCell(row),
			},
		];
		this.props.tabUrlMap["docs/"].columns = this.docsColumnConfig;
		this.props.tabUrlMap["public/"].columns = this.docsColumnConfig;
		this.props.tabUrlMap["private/"].columns = this.docsColumnConfig;
		this.props.tabUrlMap["email/"].columns = this.emailColumnConfig;
		this.props.tabUrlMap["notes/"].columns = this.notesColumnConfig;

		this.keyboardShortcuts = {
			"ctrl+a": (e) => {
				this.headerBoxClicked();
				e.preventDefault();
				return false;
			},
			"ctrl+c": this.copy,
			"ctrl+x": this.cut,
			"ctrl+v": this.paste,
			"ctrl+n": (e) => {
				e.preventDefault();
				return false;
			},
			"ctrl+u": () => {},
			"meta+a": (e) => {
				this.headerBoxClicked();
				e.preventDefault();
				return false;
			},
			"meta+c": this.copy,
			"meta+x": this.cut,
			"meta+v": this.paste,
			"meta+n": (e) => {
				e.preventDefault();
				return false;
			},
			"meta+u": () => {},
			del: () => {
				attemptDelete(this.props);
			},
		};
	}
	componentDidMount() {
		this.props.setResizeElement("dropzoneTarget");
		if (!this.props.clioAccessCodeStatus)
			this.props.clioIntegrationAccessRedirectCodeStatus();
	}
	handleKeyboardShortcut(key, event) {
		return this.keyboardShortcuts[key](event);
	}
	setItemsMatchingFilter(itemsMatchingFilter, lastActiveFilterId) {
		// console.log("setItemsMatchingFilter", lastActiveFilterId);
		if (
			itemsMatchingFilter.length != this.state.itemsMatchingFilter.length
		) {
			this.setState({ itemsMatchingFilter });
		}
	}
	headerBoxClicked() {
		var config = { Object: {}, Folder: {} /*, header: newChecked*/ };
		if (this.state.itemsMatchingFilter.length) {
			this.state.itemsMatchingFilter.forEach((item) => {
				let originalItem = item._original ? item._original : item;
				config[objectTypeMap[originalItem.type]][originalItem.id] = {
					...originalItem,
				};
			});
		} else {
			this.props.documentList
				.filter(filterMap[this.state.showDeleted ? "All" : "Active"])
				.forEach((item) => {
					config[objectTypeMap[item.type]][item.id] = { ...item };
				});
		}

		this.props.setAllCheckboxes(selectionRootMap[this.props.root], config);
	}
	folderCell(row) {
		return (
			<NameCell
				isDeleted={row.original.isDeleted}
				noStar
				row={row}
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreFolders
						: this.props.deleteFolders
					).call(
						null,
						[row.original.id],
						this.props.matterID,

						this.props.apiBasepath
					);
				}}
				menuItems={[
					{
						text: "Rename",
						onSelect: () =>
							this.props.showEditFolderDialog({
								folderID: row.original.id,
								matterID: this.props.matterID,
								currentName: row.original.filename,
								apiBasepath: this.props.apiBasepath,
								currentLocationFilesAndFolders:
									this.props.documentList,
							}),
					},
					{
						text: row.original.isDeleted
							? "Restore Folder"
							: "Delete Folder",
						onSelect: () =>
							(row.original.isDeleted
								? this.props.restoreFolders
								: this.props.deleteFolders
							).call(
								null,
								[row.original.id],
								this.props.matterID,

								this.props.apiBasepath
							),
					},
				]}
			>
				<Link
					to={encodeFullpath(
						this.props.urlPrefix +
							((this.props.fullpath || "") +
								row.original.filename) +
							"/"
					)}
				>
					{row.original.filename}
				</Link>
			</NameCell>
		);
	}
	documentActionsCell(row) {
		return (
			<ActionsTableCell
				row={row}
				numColumns={4}
				isDeleted={row.original.isDeleted}
				isFavorite={row.original.isFavorite}
				isCheckedOut={
					row.original.lockStatus === 2 ||
					row.original.lockStatus === 3
				}
				isInUse={
					row.original.lockStatus === 1 ||
					row.original.lockStatus === 3
				}
				showCheckedOutSpace
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreDocuments
						: this.props.deleteDocuments
					).call(
						null,
						[row.original.id],
						this.props.matterID,
						this.props.apiBasepath,
						this.props.root
					);
				}}
				starClickHandler={() => {
					this.props.setFavorite(
						row.original.id,
						!row.original.isFavorite,
						this.props.matterID,
						this.props.apiBasepath
					);
				}}
			/>
		);
	}
	folderActionsCell(row) {
		return (
			<ActionsTableCell
				row={row}
				numColumns={4}
				isDeleted={row.original.isDeleted}
				isFavorite={row.original.isFavorite}
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreFolders
						: this.props.deleteFolders
					).call(
						null,
						[row.original.id],
						this.props.matterID,
						this.props.apiBasepath,
						this.props.root
					);
				}}
				starClickHandler={() => {
					this.props.setFavoriteFolder(
						row.original.id,
						!row.original.isFavorite,
						this.props.matterID,
						this.props.apiBasepath
					);
				}}
			/>
		);
	}
	folderActionsCellNoFavorite(row) {
		return (
			<ActionsTableCell
				row={row}
				numColumns={4}
				isDeleted={row.original.isDeleted}
				isFavorite={row.original.isFavorite}
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreFolders
						: this.props.deleteFolders
					).call(
						null,
						[row.original.id],
						this.props.matterID,
						this.props.apiBasepath,
						this.props.root
					);
				}}
			/>
		);
	}
	emailActionsCell(row) {
		return (
			<ActionsTableCell
				row={row}
				isDeleted={row.original.isDeleted}
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreEmails
						: this.props.deleteEmails
					).call(
						null,
						[row.original.id],
						this.props.matterID,
						this.props.apiBasepath,
						this.props.root
					);
				}}
			/>
		);
	}
	noteActionsCell(row) {
		return (
			<ActionsTableCell
				row={row}
				isDeleted={row.original.isDeleted}
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreNotes
						: this.props.deleteNotes
					).call(
						null,
						[row.original.id],
						this.props.matterID,
						this.props.apiBasepath,
						this.props.root
					);
				}}
			/>
		);
	}
	documentCell(row) {
		const docCanBeOpenedInLauncher =
			this.props.launcherInstalled &&
			(!row.original.lockedBySub ||
				row.original.lockedBySub === this.props.cognitoSub);

		return (
			<NameCell
				row={row}
				menuItems={[
					{
						text: "Properties",
						href: "/docs/" + row.original.id,
					},
					{
						text: "Download a Copy",
						onSelect: () => {
							this.props.getSignedUrlForDocumentAndDownload(
								row.original.id
							);
						},
					},
					{
						text: "Rename",
						onSelect: () => {
							this.renameDocumentHandler(
								row.original.id,
								row.original.filename
							);
						},
					},
					convertToPDFAllowed(row.original.filename)
						? {
								text: "Convert to PDF",
								disabled:
									row.original.lockedBySub &&
									row.original.lockedBySub !==
										this.props.cognitoSub,
								disabledHoverText:
									"This document is locked by someone else.",
								onSelect: (e) => {
									this.props.convertDocumentToPDF({
										sourceDocumentID: row.original.id,
										fileToConvert: row.original.filename,
										matterID: this.props.matterID,
										actionType: "createPDF",
										basepath: this.props.originalPrefix, // this is passed so that the document list can refresh if they haven't navigated away
									});
								},
							}
						: "",
					ocrOnDemandAllowed(row.original.filename)
						? {
								text: "Request OCR",
								disabled:
									!!row.original.lockedBySub ||
									!this.props.isOcrEnabled ||
									this.props.isTenantOverOcrLimit,
								disabledHoverText: !this.props.isOcrEnabled
									? "Your firm does not have OCR enabled. Please contact the Uptime Help Desk for more information."
									: this.props.isTenantOverOcrLimit
										? "You have reached your monthly OCR Limit. Please contact your Firm Administrator for more information."
										: row.original.lockedBySub &&
											  row.original.lockedBySub ===
													this.props.cognitoSub
											? "You have this document open in the launcher"
											: "This document is locked by another user",

								onSelect: (e) => {
									this.props.requestOCR(
										row.original.id,
										row.original.filename
									);
								},
							}
						: "",
				]
					.concat(
						getCheckoutMenuItems(
							row.original.lockedBy,
							row.original.lockedBySub,
							row.original.lockStatus,
							row.original.id,
							this.props.apiBasepath,
							this.props.cognitoSub,
							this.props.setLockStatus,
							this.props.matterID,
							this.props.canOverrideDocumentLocks
						)
					)
					.concat({
						text: row.original.isDeleted
							? "Restore File"
							: "Delete File",
						disabled:
							row.original.lockedBySub &&
							row.original.lockedBySub !== this.props.cognitoSub,
						onSelect: () =>
							(row.original.isDeleted
								? this.props.restoreDocuments
								: this.props.deleteDocuments
							).call(
								null,
								[row.original.id],
								this.props.matterID,

								this.props.apiBasepath,
								this.props.root
							),
					})}
				otherButtons={[
					{
						icon: "sparkles",
						onClick: () => {
							this.setState(
								{
									previewSelectedRow: row.original,
									docCanBeOpenedInLauncher,
								},
								this.openDocumentAIRightPane
							);
						},
					},
					{
						icon: "eye",
						onClick: () => {
							this.setState(
								{
									previewSelectedRow: row.original,
									docCanBeOpenedInLauncher,
								},
								this.openDocumentPreviewRightPane
							);
						},
					},
				]}
			>
				{docCanBeOpenedInLauncher ? (
					<ButtonLink
						onClick={(e) => {
							this.clickedFile(row.original.id);
							e.preventDefault();
							return false;
						}}
					>
						{row.value}
					</ButtonLink>
				) : (
					<span>{row.value}</span>
				)}
			</NameCell>
		);
	}
	emailCell(row) {
		return (
			<NameCell
				row={row}
				isDeleted={row.original.isDeleted}
				noStar
				trashClickHandler={() => {
					(row.original.isDeleted
						? this.props.restoreEmails
						: this.props.deleteEmails
					).call(
						null,
						[row.original.id],
						this.props.matterID,
						this.props.apiBasepath,
						this.props.root
					);
				}}
				menuItems={[
					{
						text: "Download a Copy",
						onSelect: () => this.clickedEmail(row.original.id),
					},
					{
						text: "Edit Email Short Note",
						onSelect: () =>
							this.props.openGlobalDialog(
								{
									label: "Edit Email Short Note",
									value: row.original.notes,
									submitButtonLabel: "Update Note",
								},
								(state) => {
									this.props.updateEmailNote(
										row.original.id,
										state.value
									);
								},
								(state) => {
									return {
										canSubmit:
											state.value !== row.original.notes,
										erroMessage: "",
									};
								}
							),
					},

					{
						text: row.original.isDeleted
							? "Restore Email"
							: "Delete Email",
						onSelect: () =>
							(row.original.isDeleted
								? this.props.restoreEmails
								: this.props.deleteEmails
							).call(
								null,
								[row.original.id],
								this.props.matterID,

								this.props.apiBasepath,
								this.props.root
							),
					},
				]}
				otherButtons={[
					{
						icon: "eye",
						onClick: () => {
							this.setState(
								{ previewSelectedRow: row.original },
								this.openEmailPreviewRightPane
							);
						},
					},
				]}
			>
				<ButtonLink
					onClick={(e) => {
						this.clickedEmail(row.original.id);
						e.preventDefault();
						return false;
					}}
				>
					{row.original.subjectline}
				</ButtonLink>
			</NameCell>
		);
	}
	noteCell(row) {
		return (
			<NameCell
				row={row}
				isDeleted={row.original.isDeleted}
				noStar
				menuItems={[
					{
						text: row.original.isDeleted
							? "Restore Note"
							: "Delete Note",
						onSelect: () =>
							(row.original.isDeleted
								? this.props.restoreNotes
								: this.props.deleteNotes
							).call(
								null,
								[row.original.id],
								this.props.matterID,

								this.props.apiBasepath,
								this.props.root
							),
					},
				]}
			>
				<Link to={"/notes/" + row.original.id}>
					{row.original.name}
				</Link>
			</NameCell>
		);
	}
	newButton() {
		const map = {
			docs: () => (
				<DropDownButton
					title="Upload..."
					iconName="upload"
					pullRight
					menuItems={this.makeUploadsMenu()}
				/>
			),
			email: () => (
				<ButtonWithIcon
					handler={this.uploadFileHandler}
					fileListChangeHandler={this.fileListChangeHandler}
					buttonText="Upload Email"
					iconName="file-upload"
					type="multi-upload"
					uploadAccept=".eml,.msg"
				/>
			),
			notes: () => (
				<ButtonWithRouter
					redirect={
						"/notes/new/" +
						this.props.matterID +
						"/" +
						this.props.directoryID +
						"/notes/" +
						(this.props.fullpath || "")
					}
					handler={this.props.accessCreateNote}
					buttonText="Create Note"
					iconName="plus-circle"
				/>
			),
		};
		map.public = map.docs;
		map.private = map.docs;
		return map[this.props.root.replace("/", "")]();
	}

	createNoteHandler() {
		this.props.accessCreateNote(
			this.props.matterID,
			this.props.directoryID,
			this.props.fullpath
		);
	}
	clickedFile(documentID) {
		this.props.openDocumentInLauncher(documentID, this.props.cognitoSub);
	}
	clickedEmail(emailID) {
		this.props.getSignedUrlForEmailAndDownload(emailID);
	}
	cut() {
		if (this.props.cutCopyAllowed) {
			this.props.cut(this.props.apiBasepath, this.props.directoryID);
		}
	}
	copy() {
		if (this.props.cutCopyAllowed) {
			this.props.copy(this.props.apiBasepath, this.props.directoryID);
		}
	}
	paste() {
		if (this.props.pasteAllowed) {
			this.props.paste(
				this.props.matterID,
				this.props.directoryID,
				this.props.apiBasepath,
				this.props.tabUrlMap[this.props.root].backendRoot, //documents, email, notes
				false //auto rename.  False means user will be prompted about duplicates
			);
		}
	}
	testGetAll() {
		this.props.testGetAllDocs();
	}

	toggleShowDeleted() {
		this.setState({ showDeleted: !this.state.showDeleted });
	}
	toggleFoldersOnTop() {
		this.props.updateUserSetting("foldersOnTop", !this.props.foldersOnTop);
	}
	uploadFileHandler() {
		console.log("upload");
	}
	bulkFileListChangeHandler(files, callback) {
		console.log("fileListChangeHandler", files, callback);
		if (this.props.root !== "email/") {
			this.props.bulkUploadMultipleFiles(
				this.props.matterID,
				this.props.apiBasepath,
				this.props.directoryID,
				files,
				false, //autoRename parameter.  False on first call
				callback
			);
		} else {
			this.props.uploadMultipleEmails(
				this.props.matterID,
				this.props.apiBasepath,
				this.props.directoryID,
				files,
				false,
				callback
			);
		}
	}
	fileListChangeHandler(files, callback) {
		console.log("fileListChangeHandler", files);
		if (this.props.root !== "email/") {
			this.props.bulkUploadMultipleFiles(
				this.props.matterID,
				this.props.apiBasepath,
				this.props.directoryID,
				files,
				false, //autoRename parameter.  False on first call
				callback
			);
		} else {
			this.props.uploadMultipleEmails(
				this.props.matterID,
				this.props.apiBasepath,
				this.props.directoryID,
				files,
				false,
				callback
			);
		}
	}

	renameDocumentHandler(documentID, currentName) {
		const { basename, extension } = splitFilename(currentName);

		this.props.openGlobalDialog(
			{
				label: "New Document Name",
				value: basename,
				submitButtonLabel: "Rename Document",
				suffix: extension,
			},
			(state) => {
				this.props.renameDocument(
					documentID,
					state.value + (extension ? "." + extension : ""),
					this.props.matterID,
					this.props.apiBasepath
				);
			},
			(state) => {
				//Check for case-insensitive duplicate in current folder
				const matchesFilename = this.props.documentList.filter(
					(item) =>
						item.type === "File" &&
						item.filename.toUpperCase() ===
							`${state.value}.${extension}`.toUpperCase() &&
						item.id !== documentID
				).length;
				const deletedFileMatch = this.props.documentList.filter(
					(item) =>
						item.type === "File" &&
						item.filename.toUpperCase() ===
							`${state.value}.${extension}`.toUpperCase() &&
						item.id !== documentID &&
						item.isDeleted
				).length;

				this.props.documentList
					.filter((item) => item.type === "File")
					.map((item) => item.filename);

				const isEmpty =
					state.value.match(/^$/) || state.value.match(/^\s/);
				const hasIllegalCharacters = state.value.match(/[<>:"/\\|?*%]/);
				return {
					canSubmit:
						!isEmpty && !matchesFilename && !hasIllegalCharacters,
					errorMessage: matchesFilename
						? "There is already a " +
							(deletedFileMatch ? "deleted" : "") +
							" file in the current directory with this name"
						: hasIllegalCharacters
							? 'New File names cannot contain any of the following characters: < > : " / \\ | ? * %'
							: isEmpty
								? "Please enter a new file name"
								: "",
				};
			}
		);
	}
	makeActionsMenu() {
		var menuItems = [
			{
				text: filmCreditsFlexHelper(
					"Include Deleted Items",
					<RowCheckbox
						style={{ left: 4 }}
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={<CheckBoxIcon fontSize="small" />}
						checked={this.state.showDeleted}
					/>
				),
				onSelect: this.toggleShowDeleted,
			},
			{
				text: filmCreditsFlexHelper(
					"Folders on Top (A-Z)",
					<RowCheckbox
						style={{ left: 4 }}
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={<CheckBoxIcon fontSize="small" />}
						checked={this.props.foldersOnTop}
					/>
				),
				onSelect: this.toggleFoldersOnTop,
			},
			{ divider: true },
			{
				text: filmCreditsFlexHelper("Select All", "(CTRL-A)"),
				onSelect: this.headerBoxClicked,
			},
			{
				text: filmCreditsFlexHelper("Cut", "(CTRL + X)"),
				onSelect: this.cut,
				disabled: !this.props.cutCopyAllowed,
			},
			{
				text: filmCreditsFlexHelper("Copy", "(CTRL + C)"),
				onSelect: this.copy,
				disabled: !this.props.cutCopyAllowed,
			},
			{
				text: filmCreditsFlexHelper("Paste", "(CTRL + V)"),
				onSelect: this.paste,
				disabled: !this.props.pasteAllowed,
			},
			{ divider: true },
			this.props.selectedItems ? getDownloadAllMenuItem(this.props) : {},
			this.props.selectedItems ? getExternalSendMenuItem(this.props) : {},
		];
		if (
			this.props.matterID > 0 &&
			this.props.clioMatterID === undefined &&
			this.props.clioIntegrationSettingStatus
		) {
			menuItems = menuItems.concat([
				{ divider: true },
				{
					text: "Link to Matter in Clio Manage",
					onSelect: () =>
						this.props.openLinkExistingMatterToClioDialog(
							this.props.matterID
						),
				},
			]);
		} else {
			if (
				this.props.matterID > 0 &&
				this.props.clioIntegrationSettingStatus
			) {
				menuItems = menuItems.concat([
					{ divider: true },
					{
						text: "Un-link Matter from Clio Manage",
						onSelect: () =>
							this.props.unlinkLXMatterfromClio(
								this.props.clioMatterID,
								this.props.matterID
							),
					},
				]);
			}
		}
		if (this.props.matterID > 0) {
			menuItems = menuItems.concat([
				{ divider: true },
				{
					text: "Export Matter",
					onSelect: () =>
						this.props.exportMatter(this.props.matterID),
				},
			]);
		}
		menuItems = menuItems.concat([
			{ divider: true },
			this.props.selectedItems ? getDeleteMenuItem(this.props) : {},
		]);
		return menuItems;
	}

	makeUploadsMenu() {
		var menuItems = [
			{
				text: "Upload File(s)",
				type: "multi-file-upload",
				iconName: "file-upload",
				fileListChangeHandler: this.bulkFileListChangeHandler,
			},
			{
				text: "Upload Folder",
				type: "multi-folder-upload",
				iconName: "folder",
				fileListChangeHandler: this.bulkFileListChangeHandler,
			},
		];
		return menuItems;
	}

	openDocumentAIRightPane() {
		this.setState({ isPaneOpen: true, aiMode: true });
	}

	openDocumentPreviewRightPane() {
		const { id, versionNumber, filename } = this.state.previewSelectedRow;

		canShowInlineImagePreview(filename)
			? this.props.getContentForDocumentPreview(
					id,
					versionNumber,
					"",
					"Image"
				)
			: this.props.getContentForDocumentPreview(
					id,
					versionNumber,
					"",
					"Document"
				);

		this.setState({ isPaneOpen: true, aiMode: false });
	}
	openEmailPreviewRightPane() {
		const { id } = this.state.previewSelectedRow;
		this.props.getContentForDocumentPreview("", "", id, "Email");

		this.setState({ isPaneOpen: true, aiMode: false });
	}
	closeRightPane() {
		this.setState({ isPaneOpen: false });
	}
	setFavoriteFromPreview() {
		this.setState({
			previewSelectedRow: {
				...this.state.previewSelectedRow,
				isFavorite: !this.state.previewSelectedRow.isFavorite,
			},
		});
		this.props.setFavorite(
			this.state.previewSelectedRow.id,
			!this.state.previewSelectedRow.isFavorite,
			this.props.matterID,
			this.props.apiBasepath
		);
	}
	render() {
		return (
			<div className="fullHeightFlex">
				<KeyboardEventHandler
					handleKeys={Object.keys(this.keyboardShortcuts)}
					onKeyEvent={this.handleKeyboardShortcut}
				/>

				<FileDropzone
					fileHandler={this.bulkFileListChangeHandler}
					enabled={this.props.root !== "notes/"}
				>
					<SelectTable
						className="fullWidth"
						actionButtons={
							<div style={{ display: "flex", gap: 8 }}>
								<ButtonWithIcon
									handler={() =>
										this.props.showNewFolderDialog({
											matterID: this.props.matterID,
											apiBasepath: this.props.apiBasepath,
											currentLocationFilesAndFolders:
												this.props.documentList,
										})
									}
									buttonText="New Folder"
									iconName="folder"
								/>
								{this.newButton()}
								<DropDownButtonWithRouter
									title="Actions"
									iconName="gavel"
									pullRight
									menuItems={this.makeActionsMenu()}
								/>
							</div>
						}
						leftSideFilterSpace={
							<div
								style={{
									display: "flex",
									marginTop: 12,
									marginRight: 12,
									marginBottom: 10,
									alignItems: "center",
								}}
							>
								<FontAwesomeIcon
									icon={
										this.props.tabUrlMap[this.props.root]
											.breadcrumbsIcon
									}
									size="2x"
									style={{
										marginRight: 16,
										fontSize: "1.6em",
										marginLeft: 12,
									}}
								/>
								<Breadcrumbs
									fullpath={this.props.fullpath}
									basepath={
										this.props.basepath + this.props.root
									}
									basepathname={this.props.breadcrumbRootName}
								/>
							</div>
						}
						data={
							this.props.loading
								? []
								: this.props.documentList &&
									this.props.documentList.filter(
										filterMap[
											this.state.showDeleted
												? "All"
												: "Active"
										]
									)
						}
						autoResize
						root={this.props.root}
						columns={
							this.props.tabUrlMap[this.props.currentRoot].columns
						}
						headerBoxClicked={this.headerBoxClicked}
						loading={this.props.loading}
						loadingMessage={this.props.loadingMessage}
						noResultsText={`No ${
							this.props.tabUrlMap[this.props.currentRoot]
								.itemNoun
						} or folders here`}
						filterable
						defaultSorted={[{ id: "filename" }]}
						onSortedChange={(newSorted) => {
							newSorted.filter((sort) => sort.id === "filename");
						}}
						orderByMethod={
							this.props.foldersOnTop
								? foldersOnTopOrderedBy
								: defaultOrderBy
						}
						tableUniqueID={this.props.tableUniqueID}
						onFilteredChange={(filteredColumns) => {
							if (!filteredColumns || !filteredColumns.length) {
								this.setItemsMatchingFilter([]);
							}
						}}
						filteringCompleteCallback={(
							filteredData,
							filterId,
							activeFilters
						) => {
							// console.log(
							// 	"filteringCompleteCallback document list\n",
							// 	filterId,
							// 	"\n",
							// 	filteredData.length,
							// 	"\n",
							// 	activeFilters
							// );
							if (activeFilters.length === 0) {
								this.setItemsMatchingFilter([]);
							} else {
								const lastActiveFilterId =
									activeFilters.slice(-1)[0].id;

								// console.log(
								// 	"activeFilters.length",
								// 	activeFilters.length
								// );
								if (activeFilters.length === 1) {
									// console.log(
									// 	"setting to filtered Data only 1 filter"
									// );
									this.setItemsMatchingFilter(
										filteredData,
										lastActiveFilterId
									);
								} else if (lastActiveFilterId === filterId) {
									// console.log("last filter", filterId);
									this.setItemsMatchingFilter(
										filteredData,
										lastActiveFilterId
									);
								} else {
									console.log("not last filter");
								}
							}
						}}
					/>
				</FileDropzone>
				<DocumentSlidingPane
					openPane={this.state.isPaneOpen}
					selectedRowData={this.state.previewSelectedRow}
					docCanBeOpenedInLauncher={
						this.state.docCanBeOpenedInLauncher
					}
					closeRightPane={this.closeRightPane}
					setFavoriteFromPreview={this.setFavoriteFromPreview}
					clickedFile={this.clickedFile}
					clickedEmail={this.clickedEmail}
					downloadCopyOfDocument={
						this.props.getSignedUrlForDocumentAndDownload
					}
					documentPreview={this.props.documentPreview}
					previewLoading={this.props.previewLoading}
					openGlobalDialog={this.props.openGlobalDialog}
					updateEmailNote={this.props.updateEmailNote}
					matterInfoVisible={this.props.matterInfoVisible}
					aiMode={this.state.aiMode}
					aiConversationFn={this.props.aiConversationAboutDocument}
					createNoteInRootOfMatter={
						this.props.createNoteInRootOfMatter
					}
					matterID={this.props.matterID}
				/>
			</div>
		);
	}
}
const attemptDelete = (props) => {
	const { selectedItems, matterID, apiBasepath, deleteMultiselect } = props;
	const count =
		Object.keys(selectedItems.Object).length +
		Object.keys(selectedItems.Folder).length;
	if (count > 0) {
		deleteMultiselect(selectedItems, matterID, apiBasepath);
	}
};
const getDeleteMenuItem = (props) => {
	var noDeletedItems = true;
	const {
		selectedItems,
		matterID,
		apiBasepath,
		deleteAllowed,
		deleteMultiselect,
		restoreMultiselect,
	} = props;
	const count =
		Object.keys(selectedItems.Object).length +
		Object.keys(selectedItems.Folder).length;
	if (selectedItems) {
		Object.keys(selectedItems.Object).forEach((key) => {
			noDeletedItems &= !selectedItems.Object[key].isDeleted;
		});
		Object.keys(selectedItems.Folder).forEach((key) => {
			noDeletedItems &= !selectedItems.Folder[key].isDeleted;
		});
	}
	const text = filmCreditsFlexHelper(
		(noDeletedItems ? "Delete" : "Restore") +
			(count ? " (" + count + ")" : ""),
		noDeletedItems ? "(DEL)" : ""
	);
	const onSelect = noDeletedItems
		? () => deleteMultiselect(selectedItems, matterID, apiBasepath)
		: () => restoreMultiselect(selectedItems, matterID, apiBasepath);
	const disabled = !deleteAllowed;

	return { text, onSelect, disabled };
};

const getExternalSendMenuItem = (props) => {
	const {
		selectedItems,
		matterID,
		apiBasepath,
		currentRoot,
		externalSendAllMultiselect,
	} = props;

	const selectedObjects = Object.keys(selectedItems.Object).length;
	const selectedFolders = Object.keys(selectedItems.Folder).length;

	const count = selectedObjects + selectedFolders;

	const allowedSendLocations = {
		"docs/": "Document",
		"public/": "Document",
		"private/": "Document",
		"email/": false,
		"notes/": false,
	};
	const sendAllowed = allowedSendLocations[currentRoot];
	const disabled = !sendAllowed || count === 0;
	var menuNoun = "Document";
	if (selectedObjects === 0) {
		menuNoun = "Folder";
	}
	if (selectedObjects > 0 && selectedFolders > 0) {
		menuNoun = "Item";
	}

	const text =
		"Send " +
		(count > 0 && sendAllowed ? pluralizeWithNum(menuNoun, count) : "");

	const validateEmails = (recipients) => {
		var allValid = true;
		recipients &&
			recipients.forEach((email, key) => {
				if (!isEmail(email.value)) {
					allValid = false;
					recipients.splice(key, 1);
				}
			});

		return { emailsValidated: allValid, recipients: recipients };
	};
	const isEmail = (email) => {
		return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
	};
	const onSelect = () => {
		props.openGlobalDialog(
			{
				label: "Send " + menuNoun + (count && count > 1 ? "s" : ""),
				recipients: [],
				submitButtonLabel: "Send",
				type: "email",
				submitIcon: ["far", "paper-plane"],
				mainText:
					"Enter recipient email addresses & and optional message to be included in the email.",
				canSubmit: false,
			},
			(state) => {
				//Submit
				console.log(state);

				const recipients = state.recipients.map((x) => x.value);
				const message = state.textAreaContent;

				externalSendAllMultiselect(
					selectedItems,
					`/${apiBasepath}`,
					matterID,
					recipients,
					message
				);
			},
			(state) => {
				//VALIDATE
				const validateResult = validateEmails(state.recipients);

				return {
					canSubmit:
						validateResult &&
						validateResult.emailsValidated &&
						validateResult.recipients &&
						validateResult.recipients.length,
					errorMessage:
						!validateResult.recipients ||
						!validateResult.recipients.length
							? "At least one valid email is required"
							: !validateResult.emailsValidated
								? "All emails must be valid"
								: "",
					recipients: validateResult.recipients,
				};
			}
		);
	};
	return { text, onSelect, disabled };
};

const getDownloadAllMenuItem = (props) => {
	const {
		selectedItems,
		matterID,
		apiBasepath,
		downloadAllMultiselect,
		currentRoot,
		getSignedUrlAndDownload,
		getSignedUrlForDocumentAndDownload,
	} = props;

	const selectedObjects = Object.keys(selectedItems.Object).length;
	const selectedFolders = Object.keys(selectedItems.Folder).length;

	const count = selectedObjects + selectedFolders;

	const allowedDownloadLocations = {
		"docs/": "Document",
		"public/": "Document",
		"private/": "Document",
		"email/": "Email",
		"notes/": false,
	};

	const documentOrEmail = allowedDownloadLocations[currentRoot];

	var menuNoun = documentOrEmail;
	if (selectedObjects === 0) {
		menuNoun = "Folder";
	}
	if (selectedObjects > 0 && selectedFolders > 0) {
		menuNoun = "Item";
	}

	const multiDownloadAllowed = count && documentOrEmail;

	const text =
		"Download " +
		(multiDownloadAllowed ? pluralizeWithNum(menuNoun, count) : "");

	const onSelect = () => {
		if (selectedObjects === 1 && selectedFolders === 0) {
			if (documentOrEmail === "Email") {
				var prefix =
					selectedItems.Object[Object.keys(selectedItems.Object)[0]]
						.prefix;
				getSignedUrlAndDownload(prefix, "email");
			} else if (documentOrEmail === "Document") {
				var selectedDocumentID =
					selectedItems.Object[Object.keys(selectedItems.Object)[0]]
						.id;
				getSignedUrlForDocumentAndDownload(selectedDocumentID);
			}
		} else {
			downloadAllMultiselect(
				selectedItems,
				documentOrEmail,
				`/${apiBasepath}`,
				matterID
			);
		}
	};

	const disabled = !multiDownloadAllowed;
	return { text, onSelect, disabled };
};
DocumentList.propTypes = {
	clientListData: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
};
const mapStateToProps = (state, ownProps) => {
	const currentSelection =
		state.multiselect[
			ownProps.tabUrlMap[state.document.currentRoot].selectionListName
		];
	const selectionMap = {
		"docs/": "Object",
		"email/": "Object",
		"notes/": "Object",
		"public/": "Object",
		"private/": "Object",
	};
	const cutCopyAllowed =
		Object.keys(currentSelection[selectionMap[state.document.currentRoot]])
			.length +
			Object.keys(currentSelection.Folder).length >
		0;
	const pasteAllowed =
		Object.keys(state.multiselect.clipboard).length > 0 &&
		copyPasteCompatibility[
			state.multiselect.sourceBasepath.split("/")[0] + "/"
		] === copyPasteCompatibility[state.document.currentRoot] &&
		(state.multiselect.sourceDirectoryID !== state.document.directoryID ||
			!state.multiselect.cut); //not allowed to paste from a cut in the same directory
	const selectionName = selectionRootMap[state.document.currentRoot];

	const tableUniqueID =
		ownProps.tabUrlMap[state.document.currentRoot].customColumnsKey;

	return {
		title: state.router.location.pathname,
		clientListData: state.client.clientListData,
		documentList: state.document.documentList,
		prefix: state.document.prefix,
		directoryID: state.document.directoryID,
		myUserID: state.user.userID,
		loading: state.document.loading,
		loadingMessage: state.document.message,
		clipboard: state.multiselect.clipboard,
		selectedItems: state.multiselect[selectionName],
		currentRoot: state.document.currentRoot,
		pasteAllowed,
		currentSelection,
		cutCopyAllowed,
		deleteAllowed: cutCopyAllowed,
		cognitoSub: state.user.cognitoUser.attributes.sub,
		launcherInstalled: state.global.launcherInstalled,
		canOverrideDocumentLocks: state.user.canOverrideDocumentLocks,
		clioAccessCodeStatus: state.clioIntegration.clioAccessCodeStatus,
		foldersOnTop: state.userSettings.foldersOnTop,
		documentPreview: state.document.documentPreview,
		previewLoading: state.document.documentPreviewLoading,
		originalPrefix: state.document.originalPrefix,
		isOcrEnabled: !state.tenant.tenantFeaturesLoading
			? state.tenant.tenantFeatures.automaticOCR === 1
			: true,
		isTenantOverOcrLimit: !state.tenant.tenantFeaturesLoading
			? state.tenant.tenantUsage.OCR_TRANSACTIONS_THIS_MONTH >=
				state.tenant.tenantLimits.OCR_PER_MONTH_NET
			: false,
		tableUniqueID,
	};
};
export default connect(mapStateToProps, {
	getSignedUrlAndDownload,
	getSignedUrlForDocumentAndDownload,
	openGlobalDialog,
	createNewFolder,
	bulkUploadMultipleFiles,
	changeFolderName,
	deleteDocuments,
	deleteFolders,
	restoreDocuments,
	restoreFolders,
	uploadMultipleEmails,
	setFavorite,
	setFavoriteFolder,
	cut,
	copy,
	paste,
	selectAll,
	setAllCheckboxes,
	deleteMultiselect,
	restoreMultiselect,
	deleteEmails,
	restoreEmails,
	deleteNotes,
	restoreNotes,
	accessCreateNote,
	setResizeElement,
	setLockStatus,
	openDocumentInLauncher,
	updateEmailNote,
	testGetAllDocs,
	renameDocument,
	downloadAllMultiselect,
	externalSendAllMultiselect,
	exportMatter,
	getSignedUrlForEmailAndDownload,
	linkLXMatterWithClio,
	unlinkLXMatterfromClio,
	openLinkExistingMatterToClioDialog,
	clioIntegrationAccessRedirectCodeStatus,
	updateUserSetting,
	getContentForDocumentPreview,
	textAreaLabel,
	textAreaName,
	convertDocumentToPDF,
	requestOCR,
	showNewFolderDialog,
	showEditFolderDialog,
	aiConversationAboutDocument,
	createNoteInRootOfMatter,
})(DocumentList);
