import {
	FETCH_CLIENT_DETAIL_SUCCESS,
	EDIT_CLIENT,
	FETCH_CLIENT_LIST_SUCCESS,
	FETCH_MATTERS_LIST_SUCCESS,
	FETCH_CLIENT_TAGS_SUCCESS,
	FETCH_ALL_USERS_SUCCESS,
	GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST,
	GET_EVERYTHING_FOR_NEW_CLIENT_SUCCESS,
	GET_EVERYTHING_FOR_EDIT_CLIENT_SUCCESS,
	UNMOUNT_NEW_EDIT_CLIENT,
} from "../actions/types";

const initialState = {
	clientListData: [],
	clientListColumns: {},
	mattersList: [],
	clientDetails: { primaryAttorneys: [] },
	availableClientTags: [],
	availablePrimaryAttorneys: [],
	newEditClientLoading: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_CLIENT_DETAIL_SUCCESS:
			return {
				...state,
				clientDetails: action.payload.clientDetails,
			};
		case FETCH_CLIENT_LIST_SUCCESS:
			return {
				...state,
				clientListData: action.payload.clientListData,
				clientListColumns: action.payload.clientListColumns,
			};
		case GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST:
			return {
				...state,
				newEditClientLoading: true,
			};
		case GET_EVERYTHING_FOR_NEW_CLIENT_SUCCESS:
			return {
				...state,
				availableClientTags: action.payload.clientTags,
				availablePrimaryAttorneys:
					action.payload.availablePrimaryAttorneys,
				clientDetails: { clientStatus: "active", primaryAttorneys: [] },
				newEditClientLoading: false,
			};
		case GET_EVERYTHING_FOR_EDIT_CLIENT_SUCCESS:
			return {
				...state,
				availableClientTags: action.payload.clientTags,
				availablePrimaryAttorneys:
					action.payload.availablePrimaryAttorneys,
				clientDetails: action.payload.clientDetails,
				newEditClientLoading: false,
			};
		case UNMOUNT_NEW_EDIT_CLIENT:
			return {
				...state,
				newEditClientLoading: true,
			};
		case FETCH_MATTERS_LIST_SUCCESS:
			return {
				...state,
				mattersList: action.payload,
			};
		case EDIT_CLIENT:
			return {
				...state,
				info: { horse: 2, ujje: "Hamie noo" },
			};
		case FETCH_CLIENT_TAGS_SUCCESS:
			return {
				...state,
				availableClientTags: action.payload.tags,
			};
		case FETCH_ALL_USERS_SUCCESS:
			return {
				...state,
				availablePrimaryAttorneys:
					action.payload.availablePrimaryAttorneys,
			};

		default:
			return state;
	}
}
